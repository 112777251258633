import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { IGPTextField } from "./TextField";
import { TextField } from "ui";
import { modalShow, modalHide } from "app/App/actions";
import { getValue } from "./helpers";
import FormContext from "./FormContext";
import { countriesList, countryPhoneCodes } from "CountriesISO";
import { IFormChangeResponse } from "./Form";
import { Typography } from "@material-tailwind/react";
import trans from "helpers/trans";
interface IPhone extends IGPTextField {
    defaultCountryCode?: string;
}

function PhoneField({
    id,
    value,
    onChange,
    defaultCountryCode = "+47",
    ...restProps
}: IPhone) {
    const dispatch = useDispatch();
    const flagRef = useRef<HTMLDivElement>(null);

    const context = useContext(FormContext);
    const phoneNumberWithCC = getValue(id, context, value);
    const [phonePadding, setPhonePadding] = useState<number>(28);

    const { countryCode, phoneNumber } = useMemo(() => {
        let countryCode = defaultCountryCode;
        let phoneNumber = "";

        if (phoneNumberWithCC !== "" && phoneNumberWithCC !== null) {
            const temp = phoneNumberWithCC.split(" ");

            if (temp.length === 1) {
                if (countryPhoneCodes[temp[0].replace("+", "")] !== undefined) {
                    countryCode = temp[0];
                }
            } else if (temp.length > 1) {
                if (countryPhoneCodes[temp[0].replace("+", "")] !== undefined) {
                    countryCode = temp[0];
                    temp.shift();
                }

                phoneNumber = temp.join("");
            }
        }

        return {
            countryCode,
            phoneNumber,
        };
    }, [defaultCountryCode, phoneNumberWithCC]);

    const countryData = useMemo(() => {
        const currentCountry = countriesList.find(
            (item: any) => item.id === countryCode
        );

        return currentCountry;
    }, [countryCode]);

    useEffect(() => {
        if (flagRef.current) {
            const width = flagRef.current.getBoundingClientRect().width + 2;
            setPhonePadding(width + 12);
        }
    }, [countryData, flagRef]);

    const handleChangeCountryCode = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        item: any
    ) => {
        if (!!onChange) {
            onChange({ id, value: item.id + " " + phoneNumber }, context);
        } else {
            context?.onChange({ id, value: item.id + " " + phoneNumber });
        }

        dispatch(modalHide("ChooseCountryModal"));
    };

    const handleChangePhoneNumber = ({ value }: IFormChangeResponse) => {
        const numberRegex = new RegExp("^[0-9]+$");
        if (value.length > 0 && !numberRegex.test(value)) {
            return;
        }

        if (!!onChange) {
            onChange({ id, value: countryCode + " " + value }, context);
        } else {
            context?.onChange({ id, value: countryCode + " " + value });
        }
    };

    const handleShowCountryModal = () => {
        dispatch(
            modalShow("ChooseCountryModal", {
                title: trans("ui.form.phone.title"),
                onClickCountry: handleChangeCountryCode,
                searchPlaceHolder: trans("ui.form.phone.search"),
            })
        );
    };

    return (
        <TextField
            {...restProps}
            id={id}
            phoneAdornment={countryData ? phonePadding : 28}
            inputProps={{
                startAdornment: {
                    className: "flex justify-center py-1.5 !top-0 h-full",
                },
            }}
            startAdornment={
                countryData && (
                    <div
                        ref={flagRef}
                        className="flex items-center justify-center gap-1 cursor-pointer"
                        onClick={handleShowCountryModal}
                    >
                        <div
                            className={`mb-[1px] fflag ff-md fflag-${countryData.flag.toUpperCase()}`}
                        />
                        <Typography color="black">{countryData.id}</Typography>
                    </div>
                )
            }
            onChange={handleChangePhoneNumber}
            value={phoneNumber}
        />
    );
}

export default PhoneField;
