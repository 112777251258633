import { accounts, api, web } from "apiConnections";
import { AxiosRequestConfig } from "axios";

const requests = {
    logout: (data: object, config: AxiosRequestConfig) =>
        accounts.post(`/logout`, data, config),
    me: (config: AxiosRequestConfig) => api.get(`/user/me`, config),
    permissions: (config: AxiosRequestConfig) =>
        api.get(`/user/permissions`, config),
    sso: (config: AxiosRequestConfig) => web.get(`/gp/sso/auth`, config),

    meAccounts: (config: AxiosRequestConfig) =>
        accounts.get(`/user/me`, config),
};

export default requests;
