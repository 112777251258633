import "./index.css";
import store from "./rootStore";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";
import AppInit from "app/App/components/AppInit";
import "helpers/formats";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import mergedTheme from "ui/theme";
import "ui/CountrySelect/freakflags.css";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
            <ThemeProvider value={mergedTheme}>
                <AppInit />
            </ThemeProvider>
        </Provider>
    </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
