import React, {
    FocusEventHandler,
    forwardRef,
    useContext,
    useState,
} from "react";
import { getValue } from "./helpers";
import FormContext from "./FormContext";
import { IGPTextField } from "./TextField";
import { IFormChangeResponse } from "./Form";
import { TextField } from "ui";

export interface IGPNumberField extends Omit<IGPTextField, "value"> {
    maxPrecision?: number;
    precision?: number;
    value?: number | string;
    min?: number;
    max?: number;
    onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
}
const calculateFloatString = (
    val: string,
    precision: number,
    maxPrecision: number
) => {
    let floatNumber = "";
    if (val !== "") {
        floatNumber = (+val + 0.0).toFixed(precision);
        if (maxPrecision === 0) {
            floatNumber = (+val + 0.0).toFixed(0);
        } else if (precision === 0) {
            floatNumber = val;
        }
    }

    return floatNumber;
};
const NumberField = forwardRef<any, IGPNumberField>((props, ref) => {
    const {
        errorText,
        id,
        onChange,
        value,
        onBlur,
        precision = 0,
        maxPrecision = 3,
        helperText,
        ...restProps
    } = props;
    const context = useContext(FormContext);
    const inputValue = getValue(id, context, value);

    const [val, setVal] = useState(
        inputValue || inputValue === 0
            ? calculateFloatString(inputValue, precision, maxPrecision)
            : ""
    );

    const handleChange = ({ value }: IFormChangeResponse) => {
        const targetValue = value.replace(",", ".");

        let regex = new RegExp(`^\\d{0,16}([.]\\d{0,${precision}})?$`);
        if (maxPrecision === 0) {
            regex = new RegExp(`^\\d{0,16}?$`);
        } else if (precision === 0) {
            regex = new RegExp(`^\\d{0,16}([.]\\d{0,${maxPrecision}})?$`);
        }

        if (targetValue.length === 0 || regex.test(targetValue)) {
            const intValue = parseFloat(targetValue);

            if (!!onChange) {
                onChange({
                    id,
                    value: intValue,
                });
            } else {
                context?.onChange({
                    id,
                    value: intValue,
                });
            }

            setVal(targetValue);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!!onBlur) {
            onBlur(e);
        }

        setVal(calculateFloatString(val, precision, maxPrecision));
    };

    return (
        <TextField
            {...restProps}
            id={id}
            inputProps={{ inputMode: "numeric" }}
            onChange={handleChange}
            value={val}
            onBlur={handleBlur}
        />
    );
});

export default NumberField;
