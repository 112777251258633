import { XMarkIcon } from "@heroicons/react/24/outline";
import { DialogHeader, DialogHeaderProps } from "@material-tailwind/react";

import React, { forwardRef } from "react";

interface IGPModalTitle extends Omit<DialogHeaderProps, "children"> {
    onClose: (event: React.MouseEvent) => void;
    children?: React.ReactNode;
    buttonColor?: string;
}

const GPModalTitle = forwardRef<HTMLDivElement, IGPModalTitle>((props, ref) => {
    const { buttonColor, children, className, onClose, ...restProps } = props;

    return (
        <DialogHeader
            {...restProps}
            ref={ref}
            className={`font-inter ${className}`}
        >
            <div className="flex flex-row items-center justify-between w-full">
                <div>{children}</div>

                {!!onClose && (
                    <button onClick={onClose} className="outline-0">
                        <XMarkIcon
                            className="w-6 h-6"
                            color={buttonColor ? buttonColor : "black"}
                        />
                    </button>
                )}
            </div>
        </DialogHeader>
    );
});

export default GPModalTitle;
