import { Button, ButtonProps } from "@material-tailwind/react";
import { getColorByBgColor } from "helpers/colorShades";
import { forwardRef } from "react";

interface ICustomColorButton extends Omit<ButtonProps, "component"> {
    component?: any;
    to?: string;
    customcolor: string;
    border?: string;
    hovercolor?: string;
    fontcolor: string;
}

const CustomColorButton = forwardRef<HTMLButtonElement, ICustomColorButton>(
    (props, ref) => {
        const {
            children,
            className,
            customcolor,
            border,
            hovercolor,
            fontcolor,
            ...restProps
        } = props;
        const getStyles = () => {
            return {
                "--custom-color": customcolor,
                "--color": fontcolor,
                "--hover-color": hovercolor,
                "--hover-font-color": getColorByBgColor(hovercolor),
                border: border ? `2px solid ${border}` : "unset",
            };
        };

        return (
            <Button
                {...restProps}
                ref={ref}
                className={`${
                    props.hovercolor ? "custom-hover" : "button-hover"
                } custom-button font-inter ${className}`}
                style={getStyles() as React.CSSProperties}
            >
                {children}
            </Button>
        );
    }
);

CustomColorButton.defaultProps = {
    variant: "filled",
};

export default CustomColorButton;
