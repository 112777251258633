import { Spinner, Typography } from "@material-tailwind/react";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import AppContext, { IAppContext } from "app/App/context";
import { FC, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import {
    Button,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
} from "ui";
import { agentDelete } from "../actions";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";

const DeleteAgentModal: FC = () => {
    const context = useContext(AppContext) as IAppContext;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const uuid = useSelector((state: any) => state.me.uuid);

    const { message, status, isLoading, request } = useRequest();

    const handleDeleteAgent = () => {
        request(
            agentDelete(uuid, (response: AxiosResponse) => {
                if (response.status === 204) {
                    handleCloseModal();
                    navigate("/");
                }
            })
        );
    };

    const handleCloseModal = () => {
        dispatch(modalHide("DeleteAgentModal"));
    };
    return (
        <Modal
            open
            size={context.isMobile ? "xxl" : "sm"}
            handler={() => {}}
            className="relative flex flex-col w-auto h-full overflow-hidden bg-white text-Gray-80 sm:rounded-md sm:h-fit"
        >
            <ModalTitle
                onClose={handleCloseModal}
                className="px-4 py-2 text-lg font-medium text-black sm:6 sm:text-2xl"
            >
                {trans("agent.modal.delete.title")}
            </ModalTitle>

            <ModalContent className="relative flex flex-col px-4 overflow-y-auto text-black sm:6 flex-0 sm:py-6">
                <RequestMessage
                    message={message}
                    status={status}
                    className="mb-2"
                />
                <Typography className="text-base font-normal font-inter sm:text-lg sm:font-medium">
                    {trans("agent.modal.delete.confirm")}
                </Typography>
                <Typography className="mt-6 text-sm font-normal font-inter sm:text-base">
                    {trans("agent.modal.delete.info")}
                </Typography>
            </ModalContent>
            <ModalActions
                style={{
                    background: context.isMobile ? "white" : "#FFF9EF",
                }}
                className="relative bottom-auto flex flex-col items-center flex-1 w-full px-4 py-4 sm:flex-0 shrink-0 sm:rounded-b-md sm:justify-between sm:flex-nowrap sm:flex-row sm:px-8 sm:py-6"
            >
                <div className="flex flex-col items-center justify-center w-full gap-2 sm:flex-row-reverse sm:justify-start">
                    <Button
                        className={`bg-[#bf5935]  text-white px-14 py-2 flex items-center justify-center text-lg font-inter font-medium duration-300 ease-in-out w-full sm:w-32 rounded-5xl`}
                        onClick={handleDeleteAgent}
                    >
                        {isLoading ? (
                            <Spinner className="h-7 w-7" />
                        ) : (
                            trans("global.button.delete")
                        )}
                    </Button>
                    <Button
                        onClick={handleCloseModal}
                        variant="text"
                        className={`flex justify-center w-full sm:w-24 py-2 text-lg font-bold duration-300 ease-in-out  px-14 rounded-5xl text-[#02263A]`}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                </div>
            </ModalActions>
        </Modal>
    );
};

export default DeleteAgentModal;
