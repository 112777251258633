import api from "./api";
import types from "./types";
import { AnyAction } from "redux";
import { request } from "api/apiSaga";
import { call, takeLatest } from "@redux-saga/core/effects";
import { AxiosResponse } from "axios";

function* logout(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.logout,
        action,
        "post"
    );
    if (response.status === 204) {
        localStorage.removeItem("uuid");
        localStorage.removeItem("nextStep");
        localStorage.removeItem("userData");
        localStorage.removeItem("email");
        window.location.href = `/`;
    }
}

export function* me(action: AnyAction): any {
    const response = yield call(request, api.me, action);
    if (response.status === 200) {
        localStorage.removeItem("uuid");
        localStorage.removeItem("nextStep");
        localStorage.removeItem("userData");
        localStorage.removeItem("email");
    }
}

export function* meAccounts(action: AnyAction): any {
     const response = yield call(request, api.meAccounts, action);
     return response
}

function* gpSSO(action: AnyAction): any {
   yield call(request, api.sso, action, "get");
}


export const meSagas = [
    takeLatest(types.ME_REQUEST, me),
    takeLatest(types.ME_ACCOUNTS_REQUEST, meAccounts),
    takeLatest(types.LOGOUT_REQUEST, logout),
    takeLatest(types.SSO_REQUEST, gpSSO)
];
