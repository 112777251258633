import { REGISTER_SUCCESS } from "app/Agent/types";
import { AnyAction } from "redux";
import { APP_LOADED } from "types";

function general(
    state = {
        tempUser: {},
    },
    action: AnyAction
) {
    switch (action.type) {
        case APP_LOADED:
            return {
                ...state,
                tempUser: action.tempUser
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                tempUser: action.response.data.data,
            };
        default:
            return state;
    }
}

export default general;
