import { DialogFooter, DialogFooterProps } from "@material-tailwind/react";
import { forwardRef } from "react";

interface IGPModalActions extends DialogFooterProps {}

const GPModalActions = forwardRef<HTMLDivElement, IGPModalActions>(
    (props, ref) => {
        const { children, className, ...restProps } = props;

        return (
            <DialogFooter
                {...restProps}
                ref={ref}
                className={`font-inter ${className}`}
            >
                {children}
            </DialogFooter>
        );
    }
);

export default GPModalActions;
