export function adjust(color, amount) {
    return (
        "#" +
        color
            .replace(/^#/, "")
            .replace(/../g, (color) =>
                (
                    "0" +
                    Math.min(
                        255,
                        Math.max(0, parseInt(color, 16) + amount)
                    ).toString(16)
                ).substr(-2)
            )
    );
}

export function getColorByBgColor(hexColor) {
    if (!hexColor) return "";

    hexColor = hexColor.replace("#", "");
    const bigint = parseInt(hexColor, 16);
    const R = (bigint >> 16) & 255;
    const G = (bigint >> 8) & 255;
    const B = bigint & 255;

    const brightness = R * 0.299 + G * 0.587 + B * 0.114;

    return brightness > 186 ? "#000000" : "#FFFFFF";
}

export function contrast(color1, color2) {
    const brightness1 = getBrightness(color1);
    const brightness2 = getBrightness(color2);
    const contrastVal = Math.abs(brightness2 - brightness1);
    return contrastVal <= 15;
}

export function adjustContrast(color1, color2) {
    const brightness1 = getBrightness(color1);
    const brightness2 = getBrightness(color2);
    const contrastVal = Math.abs(brightness2 - brightness1);
    const contrastWithBlack = Math.abs(brightness2 - getBrightness("#000000"));
    const contrastWithWhite = Math.abs(brightness2 - getBrightness("#FFFFFF"));
    if (!color2) return color2;
    if (contrastVal <= 15) {
        const adjustedColor2 =
            contrastWithBlack > contrastWithWhite
                ? adjust(color2, -25)
                : adjust(color2, 25);
        return adjustedColor2;
    }

    return color2;
}

function getBrightness(hexColor) {
    if (!hexColor) return 0;

    hexColor = hexColor.replace("#", "");
    const bigint = parseInt(hexColor, 16);
    const R = (bigint >> 16) & 255;
    const G = (bigint >> 8) & 255;
    const B = bigint & 255;

    return R * 0.299 + G * 0.587 + B * 0.114;
}
