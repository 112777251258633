import { getOrganisation, organisationsFetch } from "../actions";
import {
    ArrowPathIcon,
    ChevronLeftIcon,
    MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Spinner, Typography } from "@material-tailwind/react";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import AppContext, { IAppContext } from "app/App/context";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { startCase } from "lodash";
import { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
    TextField,
} from "ui";

interface IFindOrganisationsModal {
    onChangeSearch: Function;
    onPick: Function;
}

const FindOrganisationsModal: FC<IFindOrganisationsModal> = ({ onPick }) => {
    const context = useContext(AppContext) as IAppContext;
    const dispatch = useDispatch();

    const [errMsg, setErrMsg] = useState<string>("");
    const [status, setStatus] = useState<number>(0);
    const [data, setData] = useState<any>([]);
    const [view, setView] = useState<number>(0);
    const [search, setSearch] = useState<string>("");

    const { isLoading, request } = useRequest();
    const { isLoading: isLoadingOrganisation, request: requestOrganisation } =
        useRequest();

    const handleCloseModal = () => {
        dispatch(modalHide("FindOrganisationsModal"));
    };

    const handleOrganisation = () => {
        if (search.length > 0) {
            setErrMsg("");
            setStatus(0);
            const isOrgNumber = /^\d+$/.test(search);
            if (isOrgNumber) {
                requestOrganisation(
                    getOrganisation(search, (response: AxiosResponse | any) => {
                        setStatus(response.status);
                        setErrMsg("");
                        if (response.status === 200) {
                            setData([response?.data]);
                            setView(1);
                            setStatus(response.status);
                        } else if (response.status === 400) {
                            const msg =
                                response.data?.valideringsfeil?.[0].feilmelding;
                            setErrMsg(msg);
                        }
                    })
                );
            } else
                request(
                    organisationsFetch(
                        {
                            params: {
                                navn: search,
                                size: 50,
                            },
                        },
                        (response: AxiosResponse) => {
                            setStatus(response.status);
                            setErrMsg("");
                            if (response.status === 200) {
                                setData(response?.data?._embedded?.enheter);
                                setView(1);
                            }
                        }
                    )
                );
        } else setData([]);
    };

    const handleClearInput = () => {
        setSearch("");
        setData([]);
        setView(0);
        setErrMsg("");
        setStatus(0);
    };

    const handlePick = (org: any) => {
        onPick(org);
        handleCloseModal();
    };
    const handleChangeInput = (e: any) => {
        setSearch(e.value);
    };

    const renderSearch = () => {
        return (
            <>
                <RequestMessage message={errMsg} status={status} />
                <div className="relative">
                    <TextField
                        autoFocus={true}
                        id="searchOrg"
                        startAdornment={
                            <MagnifyingGlassIcon
                                className="w-6 h-6"
                                color="gray"
                            />
                        }
                        onChange={handleChangeInput}
                        value={search}
                        placeholder={trans("agent.modal.findOrg.placeholder")}
                        className="!mb-0"
                        inputProps={{
                            className: ` ${
                                status >= 400 && "input-error border-red-700"
                            }  font-inter !text-base text-[#323947]`,
                        }}
                        fullWidth
                    />
                </div>
            </>
        );
    };

    const renderResults = () => {
        return (
            <div className="flex flex-col gap-8 h-fit">
                <div className="flex flex-col gap-3">
                    <Typography className="text-lg font-medium font-inter">
                        {trans("agent.modal.findOrg.resultsSearch")}: {search}
                    </Typography>
                    <Typography className="text-lg font-medium font-inter">
                        {trans("agent.modal.findOrg.results")}:{" "}
                        {data ? data.length : 0}{" "}
                    </Typography>
                </div>
                {data?.length > 0 && (
                    <div className="h-[calc(100vh-274px)] sm:h-[200px] min-h-[200px] overflow-auto">
                        <table className="overflow-x-scroll">
                            <thead className="sticky top-0 z-10 bg-white">
                                <tr className="text-lg font-medium font-inter">
                                    <th className="sticky min-w-[200px] max-w:36 py-2 px-4 text-start">
                                        {trans("agent.modal.findOrg.orgNo")}
                                    </th>
                                    <th className="sticky min-w-[200px] max-w:36 py-2 px-4 text-start">
                                        {trans("agent.modal.findOrg.name")}
                                    </th>
                                    <th className="sticky min-w-[200px] max-w:36 py-2 px-4 text-start">
                                        {trans("agent.modal.findOrg.post")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-auto ">
                                {data.map((org: any, idx: number) => {
                                    return (
                                        <tr
                                            key={`${org.organisasjonsnummer}-${idx}`}
                                            className="cursor-pointer hover:bg-gray-100"
                                            onClick={() => handlePick(org)}
                                        >
                                            <td className="min-w-[200px] max-w:36 py-2 px-4 text-base font-normal font-inter">
                                                {org.organisasjonsnummer}
                                            </td>
                                            <td className="min-w-[200px] max-w:36 py-2 px-4 cursor-pointer text-base font-medium text-[#2097F6] font-inter">
                                                {org.navn}
                                            </td>
                                            <td className="min-w-[200px] max-w:36 py-2 px-4 ">
                                                {org.forretningsadresse
                                                    ?.postnummer &&
                                                org.forretningsadresse?.poststed
                                                    ? `${
                                                          org.forretningsadresse
                                                              ?.postnummer
                                                      } ${startCase(
                                                          org.forretningsadresse?.poststed?.toLowerCase()
                                                      )}`
                                                    : !org.forretningsadresse
                                                          ?.postnummer &&
                                                      org.forretningsadresse
                                                          ?.poststed
                                                    ? `${startCase(
                                                          org.forretningsadresse?.poststed?.toLowerCase()
                                                      )}`
                                                    : org?.postadresse?.poststed
                                                    ? startCase(
                                                          org.postadresse.poststed.toLowerCase()
                                                      )
                                                    : ""}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    };

    return (
        <Modal
            open
            size={
                context.isMobile || context.winSize.height <= 481 ? "xxl" : "md"
            }
            handler={() => {}}
            className={`overflow-y-auto relative flex flex-col w-auto overflow-hidden bg-white text-Gray-80 sm:rounded-md  ${
                context.winSize.height <= 481
                    ? "h-full !min-h-[100vh] w-full"
                    : "h-full sm:h-fit"
            }`}
        >
            <ModalTitle
                onClose={handleCloseModal}
                className="px-4 text-xl font-medium text-black py-2xl sm:6 sm:text-2xl"
            >
                {view === 0 ? (
                    trans("agent.modal.findOrg.title")
                ) : (
                    <div
                        className="flex flex-row items-center gap-4 cursor-pointer"
                        onClick={() => setView(0)}
                    >
                        <ChevronLeftIcon className="w-6 h-6 cursor-pointer" />
                        <Typography className="text-xl font-medium sm:text-2xl ">
                            {trans("agent.modal.findOrg.search")}
                        </Typography>
                    </div>
                )}
            </ModalTitle>

            <ModalContent className="relative flex flex-col flex-1 px-4 mb-0 overflow-y-auto text-black sm:6 flex-0 sm:py-6">
                {view === 0 ? renderSearch() : renderResults()}
            </ModalContent>
            <ModalActions className="bg-[#FFF9EF] relative flex flex-col items-center w-full px-4 py-4 sm:bottom-auto shrink-0 sm:rounded-b-md sm:justify-between sm:flex-nowrap sm:flex-row sm:px-8 sm:py-6">
                <div className="flex flex-col items-center justify-end w-full gap-3 text-black sm:gap-2 sm:flex-row">
                    <div
                        onClick={handleClearInput}
                        className="flex flex-row items-center justify-center gap-2 font-medium cursor-pointer "
                    >
                        <ArrowPathIcon className="h-6 cursor-pointer w-h" />
                        {trans("agent.modal.findOrg.reset")}
                    </div>
                    <div className="flex flex-col-reverse sm:flex-row">
                        <Button
                            onClick={handleCloseModal}
                            variant="text"
                            className={`flex justify-center w-full sm:w-24 py-2 text-lg font-bold duration-300 ease-in-out  px-14 rounded-5xl text-[#02263A]`}
                        >
                            {trans("global.button.close")}
                        </Button>
                        {view === 0 && (
                            <Button
                                disabled={search.length === 0}
                                className={`bg-[#bf5935] md:w-[130px] h-10 text-white px-14 py-2 flex items-center justify-center text-lg font-inter font-medium duration-300 ease-in-out w-full sm:w-32 rounded-5xl`}
                                onClick={handleOrganisation}
                            >
                                {isLoading || isLoadingOrganisation ? (
                                    <Spinner className="w-7 h-7" />
                                ) : (
                                    trans("global.button.search")
                                )}
                            </Button>
                        )}
                    </div>
                </div>
            </ModalActions>
        </Modal>
    );
};

export default FindOrganisationsModal;
