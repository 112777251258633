import React, {
    FC,
    MouseEventHandler,
    ReactNode,
    useContext,
    useEffect,
    useRef,
} from "react";
import { getHelperText, getValue, regexValue } from "./helpers";
import FormContext from "./FormContext";

export interface IGPTextField {
    autoFocus?: boolean;
    errorText?: string;
    id: string;
    onChange?: Function;
    regex?: string;
    helperText?: string;
    value?: string;
    label?: string | ReactNode;
    type?: string;
    startAdornment?: ReactNode;
    placeholder?: string;
    fullWidth?: true;
    className?: string;
    onClick?: MouseEventHandler<HTMLInputElement>;
    ref?: any;
    phoneAdornment?: number;
    endAdornment?: ReactNode;
    inputProps?: any;
    onBlur?: any;
    disabled?: boolean;
}

const GPTextField: FC<IGPTextField> = (props) => {
    const {
        autoFocus,
        inputProps,
        errorText,
        fullWidth,
        helperText,
        id,
        label,
        onChange,
        regex,
        startAdornment,
        className,
        type,
        value,
        onClick,
        phoneAdornment,
        endAdornment,
        ...restProps
    } = props;

    const context = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (autoFocus && inputRef.current) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    }, [autoFocus, inputRef]);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = regexValue(id, context, regex, event.target.value);

        if (!!onChange) {
            onChange({ id, value: val }, context);
        } else {
            context?.onChange({ id, value: val });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    return (
        <div className={`mb-6 ${className}`}>
            <label
                className={`block leading-6 font-inter font-normal text-base mb-2`}
            >
                {label}
            </label>
            <div
                className={`relative rounded shadow-sm inline-flex items-center mt-0 ${
                    fullWidth ? "w-full" : "w-52"
                }`}
            >
                {startAdornment && (
                    <span
                        className={`absolute left-0 h-6 ml-2 top-1/4 ${inputProps.startAdornment?.className}`}
                    >
                        {startAdornment}
                    </span>
                )}
                <input
                    {...restProps}
                    {...inputProps}
                    ref={inputRef}
                    type={type}
                    name={id}
                    id={id}
                    style={{
                        paddingLeft:
                            startAdornment && phoneAdornment
                                ? `${phoneAdornment}px`
                                : startAdornment
                                ? "40px"
                                : "8px",
                        paddingRight: endAdornment ? "40px" : "8px",
                    }}
                    className={`text-left block w-full h-12 rounded border-[0.5px] border-Gray-40 py-1.5 outline-0 focus:border-2 focus:border-black text-Gray-70 placeholder:text-Gray-40 text-base leading-6  ${
                        isError &&
                        "input-error border-red-700  focus:border-red-700"
                    } ${type === "button" ? "cursor-pointer" : ""} ${
                        inputProps.className
                    }`}
                    onChange={onChangeValue}
                    value={getValue(id, context, value)}
                    {...(onClick && { onClick })}
                />
                {endAdornment && (
                    <span className="absolute right-0 h-6 mr-2 top-1/4">
                        {endAdornment}
                    </span>
                )}
            </div>
            {inputHelperText && (
                <span
                    className={`w-full block text-xs my-1 ${
                        isError ? "text-red-700" : "text-white"
                    }`}
                >
                    {inputHelperText}
                </span>
            )}
        </div>
    );
};

GPTextField.defaultProps = {
    errorText: "",
    helperText: "",
    inputProps: {},
};

export default GPTextField;
