import types from "./types";
import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";
import { IAgentInvitedModel, IAgentModel } from "./interfaces";

export const agentRegister = (data: IAgentModel, onResponse?: Function) =>
    request(
        types.REGISTER_REQUEST,
        types.REGISTER_FAILED,
        types.REGISTER_SUCCESS,
        "agentRegister",
        {
            data,
        },
        onResponse
    );

export const agentRegisterInvited = (
    data: IAgentInvitedModel,
    onResponse?: Function
) =>
    request(
        types.REGISTER_INVITED_REQUEST,
        types.REGISTER_INVITED_FAILED,
        types.REGISTER_INVITED_SUCCESS,
        "agentRegisterInvited",
        {
            data,
        },
        onResponse
    );

export const agentInvite = (data: IAgentModel, onResponse?: Function) =>
    request(
        types.INVITE_REQUEST,
        types.INVITE_FAILED,
        types.INVITE_SUCCESS,
        "agentInvite",
        {
            data,
        },
        onResponse
    );

export const login = (data: any, onResponse?: Function) =>
    request(
        types.LOGIN_REQUEST,
        types.LOGIN_FAILED,
        types.LOGIN_SUCCESS,
        "login",
        { data },
        onResponse
    );

export const newPassword = (data: IAgentModel, onResponse?: Function) =>
    request(
        types.NEW_PASSWORD_REQUEST,
        types.NEW_PASSWORD_FAILED,
        types.NEW_PASSWORD_SUCCESS,
        "newPassword",
        {
            data,
        },
        onResponse
    );

export const organisationsFetch = (
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.FETCH_ORGANISATIONS_REQUEST,
        types.FETCH_ORGANISATIONS_FAILED,
        types.FETCH_ORGANISATIONS_SUCCESS,
        "organisationsFetch",
        {
            config,
        },
        onResponse
    );

export const getOrganisation = (id: string, onResponse?: Function) =>
    request(
        types.GET_ORG_REQUEST,
        types.GET_ORG_FAILED,
        types.GET_ORG_SUCCESS,
        "getOrganisation",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const updateOrganisation = (
    id: string,
    data: any,
    onResponse?: Function
) =>
    request(
        types.UPDATE_ORGANISATION_REQUEST,
        types.UPDATE_ORGANISATION_FAILED,
        types.UPDATE_ORGANISATION_SUCCESS,
        "updateOrganisation",
        { data, url: { id } },
        onResponse
    );

export const userDelete = (
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.USER_DELETE_REQUEST,
        types.USER_DELETE_FAILED,
        types.USER_DELETE_SUCCESS,
        "userDelete",
        {
            config,
        },
        onResponse
    );

export const agentDelete = (uuid: string, onResponse?: Function) =>
    request(
        types.AGENT_DELETE_REQUEST,
        types.AGENT_DELETE_FAILED,
        types.AGENT_DELETE_SUCCESS,
        "agentDelete",
        {
            url: { uuid },
        },
        onResponse
    );

export const agentGet = (uuid: string, onResponse?: Function) =>
    request(
        types.AGENT_GET_REQUEST,
        types.AGENT_GET_FAILED,
        types.AGENT_GET_SUCCESS,
        "agentGet",
        {
            url: { uuid },
        },
        onResponse
    );

export const agentUpdate = (uuid: string, data: any, onResponse?: Function) =>
    request(
        types.AGENT_UPDATE_REQUEST,
        types.AGENT_UPDATE_FAILED,
        types.AGENT_UPDATE_SUCCESS,
        "agentUpdate",
        { data, url: { uuid } },
        onResponse
    );

export const agentPatch = (uuid: string, data: any, onResponse?: Function) => {
    return request(
        types.AGENT_PATCH_REQUEST,
        types.AGENT_PATCH_FAILED,
        types.AGENT_PATCH_SUCCESS,
        "agentPatch",
        { data, url: { uuid } },
        onResponse
    );
};

export const emailResend = (data: { uuid: string }, onResponse?: Function) =>
    request(
        types.EMAIL_RESEND_REQUEST,
        types.EMAIL_RESEND_FAILED,
        types.EMAIL_RESEND_SUCCESS,
        "emailResend",
        {
            data,
        },
        onResponse
    );


