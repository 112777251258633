import api from "api/apiReducer";
import app from "app/App/reducer";
import me from "app/Me/reducer";

const rootReducer = {
    api,
    app,
    me
};

export default rootReducer;
