import { modalHide } from "app/App/actions";
import AppContext, { IAppContext } from "app/App/context";
import trans from "helpers/trans";
import { FC, useContext } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalActions, ModalContent, ModalTitle } from "ui";

const AgentTermsModal: FC = () => {
    const context = useContext(AppContext) as IAppContext;
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(modalHide("AgentTermsModal"));
    };
    return (
        <Modal
            open
            size={context.isMobile ? "xxl" : "sm"}
            handler={() => {}}
            className="relative flex flex-col w-auto overflow-hidden bg-white rounded-none sm:rounded-md h-fit sm:max-h-[calc(95vh-1rem)]"
        >
            <ModalTitle
                onClose={handleCloseModal}
                className="px-5 py-2 text-lg font-medium leading-6"
            />

            <ModalContent className="relative flex flex-col gap-2 px-8 py-2 overflow-y-auto text-base font-normal text-black flex-0 font-inter">
                <p>{trans("agent.modal.terms.p1")}</p>
                <p>{trans("agent.modal.terms.p2")}</p>
            </ModalContent>
            <ModalActions className="relative bottom-auto flex-1 flex flex-col !items-center w-full px-4 py-4 shrink-0 sm:rounded-b-md sm:px-8 sm:py-6">
                <Button
                    variant="filled"
                    onClick={handleCloseModal}
                    className={`w-full bg-black text-white flex justify-center py-2 text-lg font-bold duration-300 ease-in-out sm:w-[185px] px-9 rounded-5xl`}
                >
                    {trans("global.button.close")}
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default AgentTermsModal;
