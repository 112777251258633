import { agentInvite } from "../actions";
import { PlusIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/solid";

import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { get } from "lodash";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, RequestMessage, TextField } from "ui";

interface IInviteAgentForm {
    data?: any;
    onLoading: Function;
}

const InviteAgentForm = forwardRef<any, IInviteAgentForm>(
    ({ data, onLoading }, ref) => {
        const dispatch = useDispatch();
        const formRef = useRef<any>(null);
        const organisationId = useSelector(
            (state: any) => state.me.organisations[0].id
        );

        const { message, status, errors, isLoading, request } = useRequest();

        useEffect(() => {
            onLoading(isLoading);
        }, [isLoading, onLoading]);

        useImperativeHandle(
            ref,
            () => ({
                submit: handleSubmit,
                loading: isLoading,
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [isLoading]
        );

        const handleAddEmail = (context: any) => {
            context.onChange([
                {
                    id: "invitations",
                    value: [...get(context.data, "invitations", []), ""],
                },
            ]);
        };
        const handleDeleteEmail = (context: any, id: number) => {
            context.onChange([
                {
                    id: "invitations",
                    value: get(context.data, "invitations", []).filter(
                        (item: string, idx: number) => idx !== id
                    ),
                },
            ]);
        };
        const handleSubmit = () => {
            if (formRef.current !== null) {
                const data: any = formRef.current.getData();
                request(
                    agentInvite(data, (response: AxiosResponse) => {
                        if (response.status === 201) {
                            dispatch(modalHide("AgentInviteModal"));
                        }
                    })
                );
            }
        };
        return (
            <>
                <RequestMessage
                    message={message}
                    status={status}
                    className="my-2"
                />
                <Form
                    ref={formRef}
                    data={{
                        organisation_id: organisationId,
                        invitations: [""],
                    }}
                    errors={errors}
                    fields={{
                        invitations: {},
                        organisation_id: {},
                    }}
                    loading={isLoading}
                    onSubmit={() => {}}
                    className="flex flex-col justify-between flex-1 w-full overflow-y-auto sm:flex-0"
                >
                    {(context: any) => {
                        return (
                            <>
                                <div className="flex-1 overflow-y-auto sm:max-h-56">
                                    {context.data?.invitations.map(
                                        (email: string, idx: number) => {
                                            return (
                                                <TextField
                                                    key={`invite-${idx}`}
                                                    autoFocus={idx === 0}
                                                    id={`invitations.${idx}`}
                                                    placeholder={trans('agent.form.inviteAgent.email')}
                                                    fullWidth
                                                    {...(idx !== 0 && {
                                                        endAdornment: (
                                                            <TrashIcon
                                                                className="w-6 h-6 text-gray-500 cursor-pointer"
                                                                onClick={() =>
                                                                    handleDeleteEmail(
                                                                        context,
                                                                        idx
                                                                    )
                                                                }
                                                            />
                                                        ),
                                                    })}
                                                />
                                            );
                                        }
                                    )}
                                </div>
                                <div
                                    className="active:bg-gray-900/20 rounded-lg hover:bg-gray-900/10 transition-all duration-300 ease-out text-lg text medium font-inter flex-row flex gap-2.5 cursor-pointer justify-center items-center py-2 px-4"
                                    onClick={() => handleAddEmail(context)}
                                >
                                    <PlusIcon className="w-6 h-6" /> {trans('agent.form.inviteAgent.invite')}
                                </div>
                            </>
                        );
                    }}
                </Form>
            </>
        );
    }
);

export default InviteAgentForm;
