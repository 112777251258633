import { AxiosRequestConfig } from "axios";
import types from "./types";
import { request } from "api/actions";

export const logout = () =>
    request(types.LOGOUT_REQUEST, types.LOGOUT_FAILED, types.LOGOUT_SUCCESS);

export const me = (onResponse?: Function) =>
    request(
        types.ME_REQUEST,
        types.ME_FAILED,
        types.ME_SUCCESS,
        "me",
        {},
        onResponse
    );

export const meAccounts = (onResponse?: Function) =>
    request(
        types.ME_ACCOUNTS_REQUEST,
        types.ME_ACCOUNTS_FAILED,
        types.ME_ACCOUNTS_SUCCESS,
        "meAccounts",
        {},
        onResponse
    );

export const sso = (config?: AxiosRequestConfig, onResponse?: Function) =>
    request(
        types.SSO_REQUEST,
        types.SSO_FAILED,
        types.SSO_SUCCESS,
        "sso",
        {
            config,
        },
        onResponse
    );
