import { ME_FAILED, ME_SUCCESS } from "./types";
import { AnyAction } from "redux";
import { APP_LOADED } from "types";

const initState = {
    applications: [],
    email: "",
    first_name: "",
    last_name: "",
    organisations: [],
    permissions: [],
    phone: "",
    role: "",
    uuid: "",
};
function general(state = initState, action: AnyAction) {
    switch (action.type) {
        case APP_LOADED:
            return {
                ...state,
                ...action.me,
            };
        case ME_SUCCESS:
            return {
                ...state,
                ...action.response.data.data,
            };
        case ME_FAILED:
            return {
                ...initState,
            };
        default:
            return state;
    }
}

export default general;
