import { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { modalHide } from "app/App/actions";
import { Modal, ModalTitle, ModalContent, ModalActions, TextField } from "ui";
import { countriesList } from "CountriesISO";
import {
    List,
    ListItem,
    ListItemPrefix,
    Typography,
} from "@material-tailwind/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import AppContext, { IAppContext } from "app/App/context";

export type CountryModel = {
    id: string;
    flag: string;
    name: string;
    search: string;
    country: string;
};

interface ICountrySelectModal {
    title: string;
    onClickCountry: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        item: CountryModel
    ) => void;
    popularCountries?: string[];
    searchPlaceHolder?: string;
}

const CountrySelectModal: FC<ICountrySelectModal> = ({
    title,
    onClickCountry,
    popularCountries,
    searchPlaceHolder,
}) => {
    const context = useContext(AppContext) as IAppContext;

    const dispatch = useDispatch();
    const [searchCountryValue, setSearchCountryValue] = useState("");

    const onClose = () => {
        dispatch(modalHide("ChooseCountryModal"));
    };

    const renderPopularCountries = (countries: string[]) => {
        const popularCountries = countriesList.filter((item) => {
            if (countries.includes(item.country)) {
                return item;
            }

            return null;
        });

        if (popularCountries)
            return (
                <>
                    {popularCountries.map((item, idx) => (
                        <div key={item.country}>
                            <ListItem
                                onClick={(event) => onClickCountry(event, item)}
                            >
                                <ListItemPrefix>
                                    <div
                                        className={`fflag ff-md fflag-${item.country.toUpperCase()}`}
                                    />
                                </ListItemPrefix>
                                <Typography variant="small">
                                    {item.name}
                                </Typography>
                            </ListItem>
                            {idx === popularCountries.length - 1 ? null : (
                                <hr />
                            )}
                        </div>
                    ))}
                    <hr />
                </>
            );
    };

    return (
        <Modal
            handler={onClose}
            open
            size={context.isMobile ? "xxl" : "xs"}
            className="flex flex-col relative bg-white text-Gray-80 sm:rounded-md h-full sm:h-[calc(90vh-1rem)] w-auto overflow-hidden"
        >
            <ModalTitle
                onClose={onClose}
                className="pt-2 pb-0 text-xl font-medium leading-6 text-black md:text"
            >
                {title}
            </ModalTitle>
            <ModalActions className="w-full py-0 pt-2">
                <TextField
                    id="country"
                    placeholder={searchPlaceHolder}
                    fullWidth
                    className="w-full"
                    autoFocus
                    onChange={(e: any) => setSearchCountryValue(e.value)}
                    startAdornment={<MagnifyingGlassIcon className="w-6 h-6" color="gray"/>}
                />
            </ModalActions>
            <ModalContent className="px-2 pt-0 overflow-y-auto">
                {!searchCountryValue && popularCountries
                    ? renderPopularCountries(popularCountries)
                    : null}
                <List className="pt-0">
                    {countriesList.map((item) => {
                        if (
                            searchCountryValue.length > 0 &&
                            item.search.indexOf(
                                searchCountryValue.toLowerCase()
                            ) === -1
                        ) {
                            return null;
                        }

                        return (
                            <div key={`flag-${item.country}`}>
                                <ListItem
                                    onClick={(event) =>
                                        onClickCountry(event, item)
                                    }
                                >
                                    <ListItemPrefix>
                                        <div
                                            className={`fflag ff-md fflag-${item.country.toUpperCase()}`}
                                        />
                                    </ListItemPrefix>
                                    <Typography variant="small" className="text-base font-normal ">
                                        {item.name}
                                    </Typography>
                                </ListItem>
                                <hr />
                            </div>
                        );
                    })}
                </List>
            </ModalContent>
        </Modal>
    );
};

CountrySelectModal.defaultProps = {
    searchPlaceHolder: "Search",
};

export default CountrySelectModal;
