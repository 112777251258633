import AboutAgentModal from "./modals/AboutAgentModal";
import AgentTermsModal from "./modals/AgentTermsModal";
import DeleteAgentModal from "./modals/DeleteAgentModal";
import FindOrganisationsModal from "./modals/FindOrganisationsModal";
import AgentInviteModal from "./modals/AgentInviteModal";
import AgentReducer from "./reducer"
import AgentSaga from "./saga";

import { registerModals } from "modals";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { configType, permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";
export const url = "/";

const getConfig = (permissions: permissionsType) => {
    const config: configType = {
        permissions: {
            read: true,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    routing.push({
        path: `/`,
        element: <AsyncComponent path="Agent/pages/AgentIndexPage" />,
    });

    routing.push({
        path: `/register`,
        element: <AsyncComponent path="Agent/pages/AgentRegisterPage" />,
    });

    routing.push({
        path: `/register-agent`,
        element: <AsyncComponent path="Agent/pages/AgentInvitedRegisterPage" />,
    });

    routing.push({
        path: `/email-send`,
        element: <AsyncComponent path="Agent/pages/AgentEmailSendPage" />,
    });

    routing.push({
        path: `/email-verified`,
        element: <AsyncComponent path="Agent/pages/AgentEmailVerifiedPage" />,
    });

    routing.push({
        path: `/set-password`,
        element: <AsyncComponent path="Agent/pages/AgentSetPasswordPage" />,
    });
    routing.push({
        path: `/settings`,
        element: <AsyncComponent path="Agent/pages/AgentSettingsPage" />,
    });

    routing.push({
        path: `/login`,
        element: <AsyncComponent path="Agent/pages/AgentLoginPage" />,
    });
    store.injectSaga?.("AgentSaga", AgentSaga);
    store.reducerManager?.add("Agent", AgentReducer)
    registerModals({
        AboutAgentModal,
        AgentTermsModal,
        DeleteAgentModal,
        FindOrganisationsModal,
        AgentInviteModal,
    });

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
