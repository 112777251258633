import InviteAgentForm from "../forms/InviteAgentForm";

import { Spinner, Typography } from "@material-tailwind/react";
import { modalHide } from "app/App/actions";
import AppContext, { IAppContext } from "app/App/context";
import trans from "helpers/trans";
import { FC, useCallback, useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalActions, ModalContent, ModalTitle } from "ui";

const AgentInviteModal: FC = () => {
    const formRef = useRef<any>(null);
    const context = useContext(AppContext) as IAppContext;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const handleInviteAgent = () => {
        formRef.current?.submit();
        setLoading(formRef.current?.loading);
    };
    const hadleLoading = useCallback((isLoading: boolean) => {
        setLoading(isLoading);
    }, []);

    const handleCloseModal = () => {
        dispatch(modalHide("AgentInviteModal"));
    };

    return (
        <Modal
            open
            size={context.isMobile ? "xxl" : "sm"}
            handler={() => {}}
        
            className={`overflow-y-auto relative flex flex-col w-auto overflow-hidden bg-white text-Gray-80 sm:rounded-md  ${
                context.winSize.height <= 481
                    ? "h-full !min-h-[100vh] w-full"
                    : "h-full sm:h-fit"
            }`}
        >
            <ModalTitle
                onClose={handleCloseModal}
                className="px-4 text-lg font-medium text-black py-2xl sm:6 sm:text-2xl"
            >
                {trans("agent.modal.invite.title")}
            </ModalTitle>

            <ModalContent className="relative flex flex-col flex-1 px-4 mb-0 overflow-y-auto text-black sm:6 sm:py-6">
                <Typography className="mb-2 text-base font-medium sm:text-lg font-inter">
                    {trans("agent.modal.invite.info")}
                </Typography>
                <InviteAgentForm
                    ref={formRef}
                    onLoading={hadleLoading}
                    data={{} as any}
                />
            </ModalContent>
            <ModalActions
                style={{
                    background: context.isMobile ? "white" : "#FFF9EF",
                }}
                className="relative flex flex-col items-center w-full px-4 py-4 mt-auto sm:mt-0 sm:bottom-auto shrink-0 sm:rounded-b-md sm:justify-between sm:flex-nowrap sm:flex-row sm:px-8 sm:py-6"
            >
                <div className="flex flex-col items-center justify-center w-full gap-2 sm:flex-row-reverse sm:justify-start">
                    <Button
                        className={`bg-[#bf5935] h-10  text-white px-14 py-2 flex items-center justify-center text-lg font-inter font-medium duration-300 ease-in-out w-full sm:w-32 rounded-5xl`}
                        onClick={handleInviteAgent}
                    >
                        {loading ? <Spinner className="w-7 h-7" /> : trans("global.button.send")}
                    </Button>
                    <Button
                        onClick={handleCloseModal}
                        variant="text"
                        className={`flex justify-center w-full sm:w-24 py-2 text-lg font-bold duration-300 ease-in-out  px-14 rounded-5xl text-[#02263A]`}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                </div>
            </ModalActions>
        </Modal>
    );
};

export default AgentInviteModal;
