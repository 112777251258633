import { Checkbox, Typography } from "@material-tailwind/react";
import React, { useContext } from "react";

import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers";
interface IGPCheckbox  {
    checked?: boolean;
    errorText?: string;
    formControlProps?: {};
    formControlLabelProps?: any;
    helperText?: string;
    label: string|React.ReactNode;
    id: string;
    value?: boolean | null;
    onChange?: Function;
    className?: string;
}

function GPCheckbox({
    id,
    label,
    onChange,
    checked,
    errorText,
    helperText,
    value,
    ...restProps
}: IGPCheckbox) {
    const context = useContext(FormContext);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = value !== undefined ? value : event.target.checked;

        if (!!onChange) {
            onChange(
                {
                    id,
                    value: val,
                    checkboxValue: event.target.value,
                    event: event,
                },
                context
            );
        } else {
            context?.onChange({
                id,
                value: event.target.checked,
                checkboxValue: event.target.value,
            });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    return (
        <>
            <Checkbox
                {...restProps}
                crossOrigin={undefined}
                checked={Boolean(getValue(id, context, checked))}
                onChange={onChangeValue}
                {...(isError && { color: "red" })}
                className="border-2 rounded checkbox checked:bg-white border-Gray-60 checked:border-Gray-60"
                label={
                    <Typography
                        variant="small"
                        className="flex items-center font-normal font-inter"
                    >
                        {label}
                    </Typography>
                }
                labelProps={{ className: "w-[90%]" }}
                iconProps={{ className: "text-Gray-60" }}
            />
            {inputHelperText?.length > 0 && (
                <Typography className="font-inter">{inputHelperText}</Typography>
            )}
        </>
    );
}

export default GPCheckbox;
