import { appTranslate, changeLocale } from "./actions";
import api from "./api";

import {
    call,
    delay,
    put,
    race,
    take,
    takeLatest,
} from "@redux-saga/core/effects";
import { request } from "api/apiSaga";
import meTypes from "app/Me/types";
import { meAccounts, me as meRequest, sso } from "app/Me/actions";
import {
    ME_ACCOUNTS_FAILED,
    ME_ACCOUNTS_SUCCESS,
    ME_FAILED,
    ME_SUCCESS,
} from "app/Me/types";
import { loadModules } from "moduleSaga";
import queryString from "query-string";
import { AnyAction } from "redux";
import {
    APP_INIT,
    APP_LOADED,
    APP_TRANSLATE_REQUEST,
    LOCALE_REQUEST,
} from "types";

interface IRaceEffect {
    failed?: any;
    success?: any;
}

function* initApp() {
    // @ts-ignore
    yield delay(500);

    const pathname = window.location.pathname;
    yield put(meAccounts());
    const meAccountResponse: IRaceEffect = yield race({
        failed: take(ME_ACCOUNTS_FAILED),
        success: take(ME_ACCOUNTS_SUCCESS),
    });

    if (meAccountResponse.success) {
        const gpSSO = meAccountResponse.success.response.data.data.GP_SSO_PT;
        const params = { GP_SSO_PT: gpSSO };
        yield put(sso({ params }));
        const ssoResponse: IRaceEffect = yield race({
            failed: take(meTypes.SSO_FAILED),
            success: take(meTypes.SSO_SUCCESS),
        });

        if (ssoResponse.success) {
            yield put(meRequest());
        }
    } else {
        yield put(meRequest());
    }

    const me: IRaceEffect = yield race({
        failed: take(ME_FAILED),
        success: take(ME_SUCCESS),
    });

    yield call(translate, appTranslate());

    const params = queryString.parse(window.location.search);
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const userUid = userData.uuid || "";
    if (params.uuid && typeof params.uuid === "string")
        localStorage.setItem("uuid", params.uuid);
    if (params.next_step && typeof params.next_step === "string")
        localStorage.setItem("nextStep", params.next_step);
    if (params.email && typeof params.email === "string")
        localStorage.setItem("email", params.email);

    const uuid = localStorage.getItem("uuid");
    const nextStep = localStorage.getItem("nextStep");
    const email = localStorage.getItem("email");

    const meAccess = ["/settings"];
    const noUser = ["/", "/login", "/register"];
    const invitedUser = ["/login", "/email-verified", "/register-agent"];
    const tempUser = ["/", "/email-send", "/login", "register"];
    const tempUserVerified = ["/email-verified", "/login", "/set-password"];

    if (me.success) {
        if (!meAccess.includes(pathname)) window.location.href = "/settings";
    } else {
        if (!userUid && !noUser.includes(pathname) && !email)
            window.location.href = "/";
        if (userUid && userUid !== uuid && !tempUser.includes(pathname))
            window.location.href = "/email-send";
        if (
            ((email && uuid) || (userUid && userUid === uuid)) &&
            nextStep &&
            (!tempUserVerified.includes(pathname) ||
                (pathname === "/email-verified" &&
                    params.uuid &&
                    params.next_step) ||
                (pathname === "/set-password" && nextStep === "login") ||
                (pathname === "/login" && nextStep === "password"))
        ) {
            window.location.href = "/email-verified";
        }
        if (
            userUid &&
            userUid === uuid &&
            nextStep &&
            email &&
            (!invitedUser.includes(pathname) ||
                (pathname === "/email-verified" &&
                    params.uuid &&
                    params.next_step &&
                    params.email) ||
                (pathname === "/register-agent" && nextStep !== "invited") ||
                (pathname === "/login" && nextStep !== "password"))
        ) {
            window.location.href = "/email-verified";
        }
    }

    // load modules
    yield call(loadModules);
    yield put({
        type: APP_LOADED,
        tempUser: userData,
        nextStep,
        invited: {
            email,
            uuid: email ? uuid : "",
        },
    });
}

function* translate(action: AnyAction): any {
    const response = yield call(request, api.translate, action);

    if (response.status === 200) {
        (window as any).translate = response.data.data;
        document.documentElement.lang = response.data.locale;
    }
}
function* locale(action: AnyAction): any {
    yield call(translate, changeLocale(action.axiosConfig.config));
}
export const appSagas = [
    takeLatest(APP_INIT, initApp),
    takeLatest(APP_TRANSLATE_REQUEST, translate),
    takeLatest(LOCALE_REQUEST, locale),
];
