import { Button, ButtonProps } from "@material-tailwind/react";
import { forwardRef } from "react";

interface IGPUIButton extends Omit<ButtonProps, "component"> {
    component?: any;
    to?: string;
}

const GPUIButton = forwardRef<HTMLButtonElement, IGPUIButton>((props, ref) => {
    const { children, className, ...restProps } = props;

    return (
        <Button {...restProps} ref={ref} className={` font-inter ${className}`}>
            {children}
        </Button>
    );
});

GPUIButton.defaultProps = {
    variant: "filled",
};

export default GPUIButton;
