import { Alert } from "@material-tailwind/react";
import trans from "helpers/trans";
interface IGPRequestMessage {
    message?: string;
    status: number;
    className?: string;
}

function GPRequestMessage(props: IGPRequestMessage) {
    const { className, status, ...restProps } = props;
    let message = props.message;

    if (!message || message.length === 0) {
        if (status === 404) {
            message = trans("ui.requestMessage.message")
        }
        if (status === 401) {
            message= trans("ui.requestMessage.unauthorized")
        }
    }

    if (!message || message.length === 0) {
        return null;
    }

    return (
        <>
            {status >= 400 ? (
                <Alert
                    icon={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                            />
                        </svg>
                    }
                    style={{ wordBreak: "break-word" }}
                    className={`flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 ${
                        className && className
                    }`}
                    {...restProps}
                >
                    {message}
                </Alert>
            ) : (
                <Alert
                    icon={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                clipRule="evenodd"
                            />
                        </svg>
                    }
                    className={`flex items-center border-l-4 text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 ${
                        className && className
                    }`}
                    {...restProps}
                >
                    {message}
                </Alert>
            )}
        </>
    );
}

GPRequestMessage.defaultProps = {};

export default GPRequestMessage;
