import React, { forwardRef } from "react";
import {
  Dialog,
  DialogProps,
} from "@material-tailwind/react";

interface IGPModal extends DialogProps {}

const GPModal = forwardRef<HTMLDivElement, IGPModal>((props, ref) => {
    const { children, ...restProps } = props;
  
    return (
      <Dialog {...restProps} ref={ref} id="agent-modal">
        {children}
      </Dialog>
    );
  });

export default GPModal;