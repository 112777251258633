import { accounts, api, breg } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    fetchOrganisations: (config: AxiosRequestConfig) =>
        breg.get(`/enheter`, config),
    getOrganisation: (config: AxiosRequestConfig, url: RequestUrlId) =>
        breg.get(`/enheter/${url.id}`),
    updateOrganisation: (
        data: object,
        config: AxiosRequestConfig,
        url: RequestUrlId
    ) => api.put(`/organisation/${url.id}`, data, config),
    register: (data: object, config: AxiosRequestConfig) =>
        api.post(`/user/registration`, data, config),
    registerInvited: (data: object, config: AxiosRequestConfig) =>
        api.post(`/user/fill-in`, data, config),
    emailResend: (data: object, config: AxiosRequestConfig) =>
        api.post(`/user/verify/resend`, data, config),
    userDelete: (config: AxiosRequestConfig) => api.get(`/user/delete`, config),
    newPassword: (data: object, config: AxiosRequestConfig) =>
        api.post(`/user/assign`, data, config),
    invite: (data: object, config: AxiosRequestConfig) =>
        api.post(`/agent/admin/invite`, data, config),

    login: (data: object, config: AxiosRequestConfig) =>
        accounts.post(`/remotelogin`, data, config),

    // change endpoints when needed
    getSmsCode: (config: AxiosRequestConfig) => api.get(`/smsCode`, config),
    smsVerify: (data: object, config: AxiosRequestConfig) =>
        api.post(`/smsCode`, data, config),

    agentDestroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/agent/${url.uuid}`, config),
    agentGet: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/agent/${url.uuid}`, config),
    agentPatch: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.patch(`/agent/${url.uuid}`, data, config),
    agentUpdate: (
        data: object,
        config: AxiosRequestConfig,
        url: RequestUrlId
    ) => api.put(`/agent/${url.uuid}`, data, config),
};

export default requests;
