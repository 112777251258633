export const ME_FAILED = "ME_FAILED";
export const ME_REQUEST = "ME_REQUEST";
export const ME_SUCCESS = "ME_SUCCESS";

export const ME_ACCOUNTS_FAILED = "ME_ACCOUNTS_FAILED";
export const ME_ACCOUNTS_REQUEST = "ME_ACCOUNTS_REQUEST";
export const ME_ACCOUNTS_SUCCESS = "ME_ACCOUNTS_SUCCESS";

export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

const types = {
    ME_FAILED,
    ME_REQUEST,
    ME_SUCCESS,

    ME_ACCOUNTS_FAILED,
    ME_ACCOUNTS_REQUEST,
    ME_ACCOUNTS_SUCCESS,

    SSO_FAILED: "SSO_FAILED",
    SSO_REQUEST: "SSO_REQUEST",
    SSO_SUCCESS: "SSO_SUCCESS",
    
    LOGOUT_FAILED,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,

};

export default types;
