import {
    APP_INIT,
    APP_LOADED,
    LOCALE_REQUEST,
    LOCALE_SUCCESS,
    MODAL_HIDE,
    MODAL_SHOW,
    SNACKBAR_HIDE,
    SNACKBAR_SHOW,
} from "types";

import { AnyAction } from "redux";

import { rs } from "helpers/reducers";
import { ME_SUCCESS } from "app/Me/types";

interface IState {
    loaded: boolean;
    groupId: string;
    modals: Array<{
        id: string;
    }>;
    nextStep: string;
    urlParams: any;
    request: any;
    settings: any;
    snackbar: any;
    sideNavType: string;
    invited: any;
}
function general(
    state: IState = {
        loaded: false,
        modals: [],
        groupId: "",
        nextStep: "password",
        urlParams: {},
        request: {},
        settings: {},
        snackbar: null,
        sideNavType: "main",
        invited: {}
    },
    action = {} as AnyAction
) {
    switch (action.type) {
        case APP_INIT:
            return rs.init(state);

        case APP_LOADED:
            return {
                ...state,
                loaded: true,
                nextStep: action.nextStep || "",
                invited: action.invited?.email ? action.invited : {}
            };

        case ME_SUCCESS:
            return {
                ...state,
                nextStep: "",
                invited: {}
            };

        case MODAL_HIDE:
            return {
                ...state,
                modals: state.modals.filter((modal) => modal.id !== action.id),
            };
        case LOCALE_REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case LOCALE_SUCCESS:
            return {
                ...state,
                loaded: true,
            };
        case MODAL_SHOW:
            return {
                ...state,
                modals: [
                    ...state.modals,
                    {
                        id: action.id,
                        props: action.props,
                    },
                ],
            };
        case SNACKBAR_HIDE:
            return {
                ...state,
                snackbar: null,
            };
        case SNACKBAR_SHOW:
            return {
                ...state,
                snackbar: action.data,
            };

        default:
            return state;
    }
}

export default general;
