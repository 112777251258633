import PropTypes from "prop-types";
import React, { useContext } from "react";

import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers";
import { Switch, SwitchButtonStylesType, Typography } from "@material-tailwind/react";

interface IGPSwitch {
    errorText?: string;
    formControlProps?: object;
    formControlLabelProps?: object;
    helperText?: string;
    id: string;
    label?: string;
    onChange?: Function;
    value?: any;
}
type ExtendedSwitchType = IGPSwitch & SwitchButtonStylesType;
function GPSwitch(props: ExtendedSwitchType) {
    const {
        errorText,
        formControlProps,
        formControlLabelProps,
        helperText,
        id,
        label,
        onChange,
        value,
        ...restProps
    } = props;

    const context = useContext(FormContext);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!!onChange) {
            onChange({ id, value: event.target.checked }, context, event);
        } else {
            context?.onChange({ id, value: event.target.checked });
        }
    };

    const { inputHelperText } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );
    
    return (
        <>
            <Switch
                {...restProps}
                crossOrigin={undefined}
                color="blue"
                checked={getValue(id, context, value)}
                onChange={onChangeValue}
            />

            {inputHelperText?.length > 0 && (
                <Typography>{inputHelperText}</Typography>
            )}
        </>
    );
}

GPSwitch.propTypes = {
    errorText: PropTypes.string,
    formControlProps: PropTypes.object,
    formControlLabelProps: PropTypes.object,
    helperText: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
};

GPSwitch.defaultProps = {
    errorText: "",
    formControlProps: {},
    formControlLabelProps: {},
    helperText: "",
    label: "",
};

export default GPSwitch;
