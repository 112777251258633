import api from "./api";
import types from "./types";

import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import { me } from "app/Me/actions";
import trans from "helpers/trans";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

function* fetchOrganisations(action: AnyAction) {
    yield call(request, api.fetchOrganisations, action);
}

function* getOrganisation(action: AnyAction) {
    yield call(request, api.getOrganisation, action);
}

function* updateOrganisation(action: AnyAction): any {
    const response = yield call(request, api.updateOrganisation, action, "put");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("agent.saga.content.updateOrg")
            })
        );
    }
}

function* newPassword(action: AnyAction): any {
    return yield call(request, api.newPassword, action, "post");
}

function* login(action: AnyAction): any {
    yield call(request, api.login, action, "post");
}

function* register(action: AnyAction): any {
    const response = yield call(request, api.register, action, "post");
    if (response.status === 201) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
    }
}
function* emailResend(action: AnyAction): any {
    return yield call(request, api.emailResend, action, "post");
}

function* userDelete(action: AnyAction) {
    localStorage.removeItem("userData");
    localStorage.removeItem("nextStep");
    localStorage.removeItem("uuid");
    localStorage.removeItem("email");
    yield call(request, api.userDelete, action);
}

function* agentDestroy(action: AnyAction): any {
    const response = yield call(request, api.agentDestroy, action, "delete");

    if (response.status === 204) {
        yield put(me());
        yield put(
            snackbarShow({
                content: trans("agent.saga.content.delete"),
            })
        );
    }
}

function* agentGet(action: AnyAction) {
    yield call(request, api.agentGet, action);
}

function* agentPatch(action: AnyAction): any {
    const response = yield call(request, api.agentPatch, action, "patch");
    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("agent.saga.content.update"),
            })
        );
    }
}

function* agentUpdate(action: AnyAction): any {
    const response = yield call(request, api.agentUpdate, action, "put");

    if (response.status === 202) {
        yield put(
            snackbarShow({
                content: trans("agent.saga.content.update"),
            })
        );
    }
}

function* agentInvite(action: AnyAction): any {
    const response = yield call(request, api.invite, action, "post");
    if (response.status === 201) {
        yield put(
            snackbarShow({
                content: trans("agent.saga.content.invitation"),
            })
        );
    }
}

function* agentRegisterInvited(action: AnyAction): any {
    yield call(request, api.registerInvited, action, "post");
}

export default function* saga() {
    yield takeLatest(types.FETCH_ORGANISATIONS_REQUEST, fetchOrganisations);
    yield takeLatest(types.GET_ORG_REQUEST, getOrganisation);
    yield takeLatest(types.UPDATE_ORGANISATION_REQUEST, updateOrganisation);
    yield takeLatest(types.NEW_PASSWORD_REQUEST, newPassword);
    yield takeLatest(types.LOGIN_REQUEST, login);
    yield takeLatest(types.REGISTER_REQUEST, register);
    yield takeLatest(types.USER_DELETE_REQUEST, userDelete);
    yield takeLatest(types.EMAIL_RESEND_REQUEST, emailResend);
    yield takeLatest(types.AGENT_DELETE_REQUEST, agentDestroy);
    yield takeLatest(types.AGENT_GET_REQUEST, agentGet);
    yield takeLatest(types.AGENT_PATCH_REQUEST, agentPatch);
    yield takeLatest(types.AGENT_UPDATE_REQUEST, agentUpdate);
    yield takeLatest(types.INVITE_REQUEST, agentInvite);
    yield takeLatest(types.REGISTER_INVITED_REQUEST, agentRegisterInvited);
}
