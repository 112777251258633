import axios from "axios";

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_DOMAIN_BASE}/api`,
    withCredentials: true,
    headers: {
        "App-Ver": process.env.REACT_APP_VERSION,
    },
});

export const accounts = axios.create({
    baseURL: `${process.env.REACT_APP_DOMAIN_ACCOUNTS}/api`,
    withCredentials: true,
});

export const breg = axios.create({
    baseURL: `https://data.brreg.no/enhetsregisteret/api`,
});

export const web = axios.create({
    baseURL: `${process.env.REACT_APP_DOMAIN_BASE}`,
    withCredentials: true,
    headers: {
        "App-Ver": process.env.REACT_APP_VERSION,
    },
});