export const FETCH_ORGANISATIONS_SUCCESS = "ORGANISATIONS/FETCH_SUCCESS";
export const REGISTER_SUCCESS = "AGENT/REGISTER_SUCCESS";
export const REGISTER_REQUEST = "AGENT/REGISTER_REQUEST";

const types = {
    FETCH_ORGANISATIONS_FAILED: "ORGANISATIONS/FETCH_FAILED",
    FETCH_ORGANISATIONS_REQUEST: "ORGANISATIONS/FETCH_REQUEST",
    FETCH_ORGANISATIONS_SUCCESS,

    GET_ORG_FAILED: "ORGANISATION/GET_FAILED",
    GET_ORG_REQUEST: "ORGANISATION/GET_REQUEST",
    GET_ORG_SUCCESS: "ORGANISATION/GET_REQUEST",

    UPDATE_ORGANISATION_FAILED: "ORGANISATION/UPDATE_FAILED",
    UPDATE_ORGANISATION_REQUEST: "ORGANISATION/UPDATE_REQUEST",
    UPDATE_ORGANISATION_SUCCESS: "ORGANISATION/UPDATE_SUCCESS",

    LOGIN_FAILED: "LOGIN_FAILED",
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",

    LOGOUT_FAILED: "LOGOUT_FAILED",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

    REGISTER_FAILED: "AGENT/REGISTER_FAILED",
    REGISTER_REQUEST: "AGENT/REGISTER_REQUEST",
    REGISTER_SUCCESS,

    REGISTER_INVITED_FAILED: "AGENT/REGISTER_INVITED_FAILED",
    REGISTER_INVITED_REQUEST: "AGENT/REGISTER_INVITED_REQUEST",
    REGISTER_INVITED_SUCCESS: "AGENT/REGISTER_INVITED_SUCCESS",

    INVITE_FAILED: "AGENT/INVITE_FAILED",
    INVITE_REQUEST: "AGENT/INVITE_REQUEST",
    INVITE_SUCCESS: "AGENT/INVITE_SUCCESS",

    NEW_PASSWORD_FAILED: "AGENT/NEW_PASSWORD_FAILED",
    NEW_PASSWORD_REQUEST: "AGENT/NEW_PASSWORD_REQUEST",
    NEW_PASSWORD_SUCCESS: "AGENT/NEW_PASSWORD_REQUEST",

    SMS_FAILED: "SMS_FAILED",
    SMS_REQUEST: "SMS_REQUEST",
    SMS_SUCCESS: "SMS_SUCCESS",

    SMS_VERIFY_FAILED: "SMS_VERIFY_FAILED",
    SMS_VERIFY_REQUEST: "SMS_VERIFY_REQUEST",
    SMS_VERIFY_SUCCESS: "SMS_VERIFY_SUCCESS",

    USER_DELETE_FAILED: "USER_DELETE_FAILED",
    USER_DELETE_REQUEST: "USER_DELETE_REQUEST",
    USER_DELETE_SUCCESS: "USER_DELETE_SUCCESS",

    AGENT_GET_FAILED: "AGENT_GET_FAILED",
    AGENT_GET_REQUEST: "AGENT_GET_REQUEST",
    AGENT_GET_SUCCESS: "AGENT_GET_SUCCESS",

    AGENT_DELETE_FAILED: "AGENT_DELETE_FAILED",
    AGENT_DELETE_REQUEST: "AGENT_DELETE_REQUEST",
    AGENT_DELETE_SUCCESS: "AGENT_DELETE_SUCCESS",

    AGENT_UPDATE_FAILED: "AGENT_UPDATE_FAILED",
    AGENT_UPDATE_REQUEST: "AGENT_UPDATE_REQUEST",
    AGENT_UPDATE_SUCCESS: "AGENT_UPDATE_SUCCESS",

    AGENT_PATCH_FAILED: "AGENT_PATCH_FAILED",
    AGENT_PATCH_REQUEST: "AGENT_PATCH_REQUEST",
    AGENT_PATCH_SUCCESS: "AGENT_PATCH_SUCCESS",

    EMAIL_RESEND_FAILED: "USER/EMAIL_RESEND_FAILED",
    EMAIL_RESEND_REQUEST: "USER/EMAIL_RESEND_REQUEST",
    EMAIL_RESEND_SUCCESS: "USER/EMAIL_RESEND_SUCCESS",
};

export default types;
